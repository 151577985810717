
<div *ngIf="roomViewType == 'allDevices' || roomViewType == 'rooms' || (roomViewType === 'activeDevices' && getAllActiveDevices().length > 0)">
  <div class="content-header flex-row no-wrap">
      <div *ngIf="roomViewType === 'rooms'" class="i-back-arrow" (click)="goBack()"></div>
      <h2 class="ellipsis">{{room?.name | uppercase}}</h2>
    <div
    *ngIf="room | checkRoomConnection : commErrorRooms"
    class="i-triangle-error-md-bl align-self-center"
  ></div>
      <div
        *ngIf="(userIsAdmin(user) || userIsOwner(user) && !demo) && (roomViewType !== 'activeDevices' )"
        class="margin-right10 margin-top10 to-right tooltip"
        (click)="positionSetup()">
          <i class="i-sort-bl"></i>
        <span class="bottom">{{ 'rearrange' | translate }}<br>{{ 'devices' | translate }}</span>
      </div>
  </div>

  <div>
      <div *ngIf="projectAvailable()"class="card-grid-container" [ngClass]="{'room-offline': room | checkRoomConnection : commErrorRooms}">
        <div *ngFor="let daliLight of getDaliLightsForRoom(room?.id) | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER : user" [ngStyle]="setPosition(daliLight.position)" (click)="onClickDaliLightCard(daliLight)">
          <div class="flex-center flex-row no-wrap">
            <div *ngIf="showPosition" class="flex-column">
              <div *ngIf="showUpPositionArrow(daliLight.position)" class="i-arrow-up" (click)="moveDeviceUp(daliLight)"></div>
            <div *ngIf="showDownPositionArrow(daliLight.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(daliLight)"></div>
            </div>
         <div [ngClass]="[daliLightIsDimmable(daliLight)? 'device-card-with-link': 'device-card']" class="width-full">
          <div class="card-row-of-icons">
            <div *ngIf="daliLight.equipmentProperties[0].value > 0; else lightOff" class="i-lightbulb-orange"></div>
            <ng-template #lightOff> <div class="i-lightbulb-black"></div></ng-template>


            <div  *ngIf="!loadingElement(daliLight.id)"
            class="i-power-bl power-btn-circle"
                  [ngClass]="{'power-on': daliLight.equipmentProperties[0].value > 0}"
                  (click)="toggleDaliLight(daliLight, $event)"></div>
                  <div *ngIf="loadingElement(daliLight.id)" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
          <div class="margin-top-5 ellipsis-2-rows">{{daliLight.name}}</div>
         </div>
          </div>
          </div>

          <div *ngFor="let rgbLight of getRgbLightsForRoom(room?.id)"
            [ngStyle]="setPosition(rgbLight.position)"
            (click)="onClickDaliRgbCard(rgbLight)">
            <div class="flex-center flex-row no-wrap">
              <div *ngIf="showPosition" class="flex-column">
                <div *ngIf="showUpPositionArrow(rgbLight.position)" class="i-arrow-up" (click)="moveDeviceUp(rgbLight)"></div>
                <div *ngIf="showDownPositionArrow(rgbLight.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(rgbLight)"></div>
              </div>
           <div class="device-card-with-link width-full">
            <div class="card-row-of-icons">
              <div *ngIf="daliRgbIsOff(rgbLight) && !daliRgbLightIsHasW(rgbLight)" class="i-light-rgb"></div>
              <div *ngIf="!daliRgbIsOff(rgbLight) && !daliRgbLightIsHasW(rgbLight)" class="i-light-rgb-orange"></div>
              <div *ngIf="daliRgbIsOff(rgbLight) && daliRgbLightIsHasW(rgbLight)" class="i-light-rgbw"></div>
              <div *ngIf="!daliRgbIsOff(rgbLight) && daliRgbLightIsHasW(rgbLight)" class="i-light-rgbw-orange"></div>



              <div *ngIf="!loadingElement(rgbLight.id)"
                class="i-power-bl power-btn-circle"
                [ngClass]="{'power-on': rgbLightIsOn(rgbLight)}"
                (click)="toggleRgbLight(rgbLight, $event)"></div>
                  <div *ngIf="loadingElement(rgbLight.id)" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
            <div class="margin-top-5 ellipsis-2-rows">{{rgbLight.name}}</div>
           </div>
            </div>
            </div>

            <div *ngFor="let daliLightGroup of getDaliGroupsForRoom(room?.id)"
              [ngStyle]="setPosition(daliLightGroup.position)"
              (click)="onClickDaliLightsGroupCard(daliLightGroup)">
              <div class="flex-center flex-row no-wrap">
                <div *ngIf="showPosition" class="flex-column">
                  <div *ngIf="showUpPositionArrow(daliLightGroup.position)" class="i-arrow-up" (click)="moveDeviceUp(daliLightGroup)"></div>
                <div *ngIf="showDownPositionArrow(daliLightGroup.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(daliLightGroup)"></div>
                </div>
             <div class="device-card-with-link width-full">
              <div class="card-row-of-icons">
                      <div class="i-lightgroup-black"></div>
                <div  *ngIf="!loadingElement(daliLightGroup.id+'on')"
                  class="i-power-on-bl power-btn-circle"
                  [ngClass]="daliLightGroupOnStatus(daliLightGroup)"
                  (click)="toggleDaliLightGroupOn(daliLightGroup, $event)"></div>
                  <div *ngIf="loadingElement(daliLightGroup.id+'on')" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <div  *ngIf="!loadingElement(daliLightGroup.id+'off')"
                  class="i-power-off-bl power-btn-circle"
                  [ngClass]="daliLightGroupOffStatus(daliLightGroup)"
                  (click)="toggleDaliLightGroupOff(daliLightGroup, $event)"></div>
                  <div *ngIf="loadingElement(daliLightGroup.id+'off')" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
              <div class="margin-top-5 ellipsis-2-rows">{{daliLightGroup.name}}</div>
             </div>
              </div>
              </div>


         <div *ngFor="let light of getLightsForRoom(room?.id) | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER : user" (click)="onClickDeviceCard(light)" [ngStyle]="setPosition(light.position)">
          <div class="flex-center flex-row no-wrap">
            <div *ngIf="showPosition" class="flex-column">
              <div *ngIf="showUpPositionArrow(light.position)" class="i-arrow-up" (click)="moveDeviceUp(light)"></div>
            <div *ngIf="showDownPositionArrow(light.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(light)"></div>
            </div>
            <div *ngIf="getPrimaryProperty(light) as primaryProperty; else noPrimaryProperty"
            [ngClass]="[deviceHasOnlyStatusProps(light)? 'device-card': 'device-card-with-link']" class="width-full">
              <div class="card-row-of-icons">
                <div *ngIf="primaryProperty.value.toString() === '0'" class="i-lightbulb-black"></div>
                <div *ngIf="primaryProperty.value.toString() === '1'" class="i-lightbulb-orange"></div>
                <ng-container *ngIf="primaryProperty.outComm.address !== 'none'">
                  <div *ngIf="!loadingElement(light.id + primaryProperty.id)"
                    class="i-power-bl power-btn-circle"
                    [ngClass]="{'power-on': primaryProperty.value.toString() === '1'}"
                    (click)="changePrimaryPropertyValue(light, primaryProperty, $event)">
                  </div>
                    <div *ngIf="loadingElement(light.id + primaryProperty.id)" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </ng-container>
              </div>
              <div class="margin-top-5 ellipsis" >{{primaryProperty.name}}</div>
              <div class="margin-top-5" *ngIf="!isMasterLight(light)">
                <div *ngIf="primaryProperty.value.toString() === '1'">{{getDataForProperty(primaryProperty).nameForTrue | genComTranslate | uppercase   }} </div>
                <div *ngIf="primaryProperty.value.toString() === '0'">{{getDataForProperty(primaryProperty).nameForFalse | genComTranslate | uppercase}}</div>
              </div>
           </div>
           <ng-template #noPrimaryProperty>
           <div  [ngClass]="[deviceHasOnlyStatusProps(light)? 'device-card': 'device-card-with-link']" class="width-full">
              <div class="card-row-of-icons">
                <div class="i-lightbulb-black"></div>
              </div>
              <div class="margin-top-5 ellipsis" >{{light.name}}</div>
           </div>
         </ng-template>
          </div>
          </div>

           <div *ngFor="let blind of getBlindsForRoom(room?.id) | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER : user" (click)="onClickDeviceCard(blind)" [ngStyle]="setPosition(blind.position)">
              <div class="flex-center flex-row no-wrap">
                <div *ngIf="showPosition" class="flex-column">
                  <div *ngIf="showUpPositionArrow(blind.position)" class="i-arrow-up" (click)="moveDeviceUp(blind)"></div>
                  <div *ngIf="showDownPositionArrow(blind.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(blind)"></div>
                </div>
                <div *ngIf="getPrimaryProperty(blind) as primaryProperty; else noPrimaryProperty"
                [ngClass]="[deviceHasOnlyStatusProps(blind)? 'device-card': 'device-card-with-link']" class="width-full">
                <div class="card-row-of-icons">
                  <div class="i-blinds"></div>
                  <div *ngIf="!loadingElement(blind.id + primaryProperty.id)" class="i-power-bl power-btn-circle" (click)="changePrimaryPropertyValue(blind, primaryProperty, $event)"></div>
                  <div *ngIf="loadingElement(blind.id + primaryProperty.id)" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div class="margin-top-5">{{primaryProperty.name}}</div>
                <div class="margin-top-5">
                  <div *ngIf="primaryProperty.value.toString() === '1'">{{getDataForProperty(primaryProperty).nameForTrue | genComTranslate | uppercase}}</div>
                  <div *ngIf="primaryProperty.value.toString() === '0'">{{getDataForProperty(primaryProperty).nameForFalse | genComTranslate | uppercase}}</div>
                </div>
            </div>
            <ng-template #noPrimaryProperty>
              <div [ngClass]="[deviceHasOnlyStatusProps(blind)? 'device-card': 'device-card-with-link']" class="width-full">
                <div class="card-row-of-icons">
                  <div class="i-blinds"></div>
                </div>
                  <div class="margin-top-5 ellipsis">{{blind.name}}</div>
                </div>
              </ng-template>
            </div>
           </div>

           <div *ngFor="let daliBlinds of getDaliBlindsForRoom(room?.id) | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER : user" [ngStyle]="setPosition(daliBlinds.position)" (click)="onClickDaliBlindCard(daliBlinds)">
            <div class="flex-center flex-row no-wrap">
              <div *ngIf="showPosition" class="flex-column">
                <div *ngIf="showUpPositionArrow(daliBlinds.position)" class="i-arrow-up" (click)="moveDeviceUp(daliBlinds)"></div>
              <div *ngIf="showDownPositionArrow(daliBlinds.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(daliBlinds)"></div>
              </div>
           <div class="device-card-with-link width-full">
            <div class="card-row-of-icons">
              <div class="i-blinds"></div>
            </div>
            <div class="margin-top-5 ellipsis-2-rows">{{daliBlinds.name}}</div>
           </div>
            </div>
            </div>

           <div *ngFor="let hvac of getHvacForRoom(room?.id) | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER : user" (click)="onClickDeviceCard(hvac)" [ngStyle]="setPosition(hvac.position)">
            <div class="flex-center flex-row no-wrap">
              <div *ngIf="showPosition" class="flex-column">
                <div *ngIf="showUpPositionArrow(hvac.position)" class="i-arrow-up" (click)="moveDeviceUp(hvac)"></div>
                <div *ngIf="showDownPositionArrow(hvac.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(hvac)"></div>
              </div>
              <div *ngIf="getPrimaryProperty(hvac) as primaryProperty; else noPrimaryProperty"
                [ngClass]="[deviceHasOnlyStatusProps(hvac)? 'device-card': 'device-card-with-link']" class="width-full">
                  <div class="card-row-of-icons">
                  <div  *ngIf="isHvacActive(hvac); else hvacInactive" class="i-hvac-orange"></div>
                  <ng-template #hvacInactive><div class="i-hvac"></div></ng-template>
                  <div *ngIf="isHvacActive(hvac)" class="flex-row flex-center">
                    <div class="temp-text">{{getCurrentTemp(hvac)}}</div>
                    <span>▶</span>
                    <div  class="temp-text">{{getTargetTemp(hvac)}}ºC</div>
                  </div>
                </div>
                <div class="margin-top-5 ellipsis">{{hvac.name}}</div>
                <div class="margin-top-5">
                  <div *ngIf="isHvacActive(hvac)">{{getDataForProperty(primaryProperty).nameForTrue | genComTranslate | uppercase}}</div>
                  <div *ngIf="!isHvacActive(hvac)">{{getDataForProperty(primaryProperty).nameForFalse | genComTranslate | uppercase}}</div>
                </div>
              </div>
              <ng-template #noPrimaryProperty >
              <div class="width-full" [ngClass]="[deviceHasOnlyStatusProps(hvac)? 'device-card': 'device-card-with-link']">
                 <div class="card-row-of-icons">
                   <div class="i-hvac"></div>
                 </div>
                 <div class="margin-top-5 ellipsis">{{hvac.name}}</div>
              </div>
            </ng-template>
          </div>
           </div>

            <div *ngFor="let secEquip of getSecurityEquipmentForRoom(room?.id) | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER : user"
              (click)="onClickDeviceCard(secEquip)"
              [ngStyle]="setPosition(secEquip.position)">
              <ng-container *ngIf="getAlarmedSecurityProperties(secEquip) as alarmedSecurityProperties">
              <div class="flex-center flex-row no-wrap">
             <div *ngIf="showPosition" class="flex-column">
                  <div *ngIf="showUpPositionArrow(secEquip.position)" class="i-arrow-up" (click)="moveDeviceUp(secEquip)"></div>
                  <div *ngIf="showDownPositionArrow(secEquip.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(secEquip)"></div>
                </div>
                <div *ngIf="getPrimaryProperty(secEquip) as primaryProperty; else noPrimaryProperty"
                [ngClass]="[deviceHasOnlyStatusProps(secEquip)? 'device-card': 'device-card-with-link']" class="width-full">
                <div class="card-row-of-icons">
                  <ng-container *ngIf="alarmedSecurityProperties.length === 0; else securityPrimaryPropertyAlarm">
                    <div class="i-security-equipment"></div>
                  </ng-container>
                  <ng-template #securityPrimaryPropertyAlarm>
                    <div class="i-security-equipment-alarm"></div>
                  </ng-template>
                  <div *ngIf="!loadingElement(secEquip.id + primaryProperty.id)" class="i-power-bl power-btn-circle" (click)="changePrimaryPropertyValue(secEquip, primaryProperty, $event)"></div>
                  <div *ngIf="loadingElement(secEquip.id + primaryProperty.id)" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div class="margin-top-5 ellipsis">{{primaryProperty.name}} </div>
                <div class="margin-top-5">
                  <div *ngIf="primaryProperty.value.toString() === '1'">{{getDataForProperty(primaryProperty).nameForTrue | genComTranslate | uppercase}}</div>
                  <div *ngIf="primaryProperty.value.toString() === '0'">{{getDataForProperty(primaryProperty).nameForFalse | genComTranslate | uppercase}}</div>
                </div>
              </div>
              <ng-template #noPrimaryProperty>
                <div *ngIf="propIsAlone(secEquip) as aloneProperty; else noSingleProperty" class="device-card width-full">
                    <div class="card-row-of-icons">
                      <ng-container *ngIf="alarmedSecurityProperties.length === 0; else securityAlonePropertyAlarm">
                        <div class="{{getDataForProperty(aloneProperty).icon}} equip-prop-status-container"></div>
                      </ng-container>
                      <ng-template #securityAlonePropertyAlarm>
                        <div class="i-security-equipment-alarm"></div>
                      </ng-template>
                    </div>
                    <div class="margin-top-5 ellipsis">{{aloneProperty.name}}</div>
                    <div class="margin-top-5">
                      <div
                        *ngIf="aloneProperty.value.toString() === '1'"
                        [ngClass]="{'red' : alarmedSecurityProperties.length > 0}" >
                        {{getDataForProperty(aloneProperty).nameForTrue | genComTranslate}}
                      </div>
                      <div *ngIf="aloneProperty.value.toString() === '0'">{{getDataForProperty(aloneProperty).nameForFalse | genComTranslate}}</div>
                    </div>
                </div>
              <ng-template #noSingleProperty>
                <div [ngClass]="[deviceHasOnlyStatusProps(secEquip)? 'device-card': 'device-card-with-link']" class="width-full">
                   <div class="card-row-of-icons">
                     <ng-container *ngIf="alarmedSecurityProperties.length === 0; else securityNoSinglePropertyAlarm">
                      <div class="i-security-equipment"></div>
                    </ng-container>
                    <ng-template #securityNoSinglePropertyAlarm>
                      <div class="i-security-equipment-alarm"></div>
                    </ng-template>
                   </div>
                   <div class="margin-top-5 ellipsis">{{secEquip.name}}</div>
                   <div *ngIf="alarmedSecurityProperties.length > 0" class="margin-top-5 ellipsis">
                     <span class="red" *ngFor="let alarm of alarmedSecurityProperties">{{alarm.name}} </span>
                    </div>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </ng-container>
        </div>

         <div *ngFor="let safeEquip of getSafetyEquipmentForRoom(room?.id) | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER : user" (click)="onClickDeviceCard(safeEquip)" [ngStyle]="setPosition(safeEquip.position)">
          <ng-container *ngIf="getAlarmedSafetyProperties(safeEquip) as alarmedSafetyProperties">
          <div class="flex-center flex-row no-wrap">
            <div *ngIf="showPosition" class="flex-column">
              <div *ngIf="showUpPositionArrow(safeEquip.position)" class="i-arrow-up" (click)="moveDeviceUp(safeEquip)"></div>
              <div *ngIf="showDownPositionArrow(safeEquip.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(safeEquip)"></div>
            </div>
            <div *ngIf="getPrimaryProperty(safeEquip) as primaryProperty; else noPrimaryProperty"
            [ngClass]="[deviceHasOnlyStatusProps(safeEquip)? 'device-card': 'device-card-with-link']" class="width-full">
              <div class="card-row-of-icons">
              <ng-container *ngIf="alarmedSafetyProperties.length === 0; else safetyPrimaryPropertyAlarm">
                <div class="i-safety-equipment"></div>
              </ng-container>
              <ng-template #safetyPrimaryPropertyAlarm>
                <div class="i-safety-equipment-alarm"></div>
              </ng-template>
              <div *ngIf="!loadingElement(safeEquip.id + primaryProperty.id)" class="i-power-bl power-btn-circle" (click)="changePrimaryPropertyValue(safeEquip, primaryProperty, $event)"></div>
              <div *ngIf="loadingElement(safeEquip.id + primaryProperty.id)" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
            <div class="margin-top-5 ellipsis">{{primaryProperty.name}}</div>
            <div class="margin-top-5">
              <div *ngIf="primaryProperty.value.toString() === '1'">{{getDataForProperty(primaryProperty).nameForTrue | genComTranslate | uppercase}}</div>
              <div *ngIf="primaryProperty.value.toString() === '0'">{{getDataForProperty(primaryProperty).nameForFalse | genComTranslate | uppercase}}</div>
            </div>
          </div>
          <ng-template #noPrimaryProperty>
            <div *ngIf="propIsAlone(safeEquip) as aloneProperty; else noSingleProperty"
              [ngClass]="[deviceHasOnlyStatusProps(safeEquip)? 'device-card': 'device-card-with-link']" class="width-full">
              <div class="card-row-of-icons">
                <ng-container *ngIf="alarmedSafetyProperties.length === 0; else safetyAlonePropertyAlarm">
                  <div class="{{getDataForProperty(aloneProperty).icon}} equip-prop-status-container"></div>
                </ng-container>
                <ng-template #safetyAlonePropertyAlarm>
                  <div class="i-safety-equipment-alarm"></div>
                </ng-template>
              </div>
              <div class="margin-top-5 ellipsis">{{aloneProperty.name}}</div>
              <div class="margin-top-5">
                <div
                  *ngIf="aloneProperty.value.toString() === '1'"
                  [ngClass]="{'red' : alarmedSafetyProperties.length > 0}" >
                  {{getDataForProperty(aloneProperty).nameForTrue}}
                </div>
                <div *ngIf="aloneProperty.value.toString() === '0'">{{getDataForProperty(aloneProperty).nameForFalse}}</div>
              </div>
          </div>
          <ng-template #noSingleProperty>
            <div [ngClass]="[deviceHasOnlyStatusProps(safeEquip)? 'device-card': 'device-card-with-link']" class="width-full">
              <div class="card-row-of-icons">
                <ng-container *ngIf="alarmedSafetyProperties.length === 0; else safetyNoSinglePropertyAlarm">
                  <div class="i-safety-equipment"></div>
                </ng-container>
                <ng-template #safetyNoSinglePropertyAlarm>
                  <div class="i-safety-equipment-alarm"></div>
                </ng-template>
              </div>
              <div class="margin-top-5 ellipsis">{{safeEquip.name}}</div>
              <div *ngIf="alarmedSafetyProperties.length > 0" class="margin-top-5 ellipsis">
                <span class="red" *ngFor="let alarm of alarmedSafetyProperties">{{alarm.name}} </span>
               </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
          </ng-container>
           </div>

            <div *ngFor="let genEquip of getGeneralEquipmentForRoom(room?.id) | filterDeviceByVisibleToUser: USE_DEVICE_VISIBLE_TO_USER : user" (click)="onClickDeviceCard(genEquip)" [ngStyle]="setPosition(genEquip.position)">
              <div class="flex-center flex-row no-wrap">
                <div *ngIf="showPosition" class="flex-column">
                  <div *ngIf="showUpPositionArrow(genEquip.position)" class="i-arrow-up" (click)="moveDeviceUp(genEquip)"></div>
                  <div *ngIf="showDownPositionArrow(genEquip.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(genEquip)"></div>
                </div>
                <div *ngIf="getPrimaryProperty(genEquip) as primaryProperty; else noPrimaryProperty"
                [ngClass]="[deviceHasOnlyStatusProps(genEquip)? 'device-card': 'device-card-with-link']" class="width-full">
                  <div class="card-row-of-icons">
                  <div class="i-general-equipment"></div>
                  <div *ngIf="!loadingElement(genEquip.id + primaryProperty.id)"
                    class="i-power-bl power-btn-circle"
                    [ngClass]="{'power-on': primaryProperty.value.toString() === '1'}"
                    (click)="changePrimaryPropertyValue(genEquip, primaryProperty, $event)"></div>
                    <div *ngIf="loadingElement(genEquip.id + primaryProperty.id)" class="lds-spinner-small margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
                <div class="margin-top-5 ellipsis">{{primaryProperty.name}}</div>
                <div class="margin-top-5">
                  <div *ngIf="primaryProperty.value.toString() === '1'">{{getDataForProperty(primaryProperty).nameForTrue | genComTranslate | uppercase}}</div>
                  <div *ngIf="primaryProperty.value.toString() === '0'">{{getDataForProperty(primaryProperty).nameForFalse | genComTranslate | uppercase}}</div>
                </div>
              </div>
              <ng-template #noPrimaryProperty>
                <div
                  *ngIf="propIsAlone(genEquip) as aloneProperty; else noSingleProperty"
                  [ngClass]="[deviceHasOnlyStatusProps(genEquip)? 'device-card': 'device-card-with-link']" class="width-full">
                  <div class="card-row-of-icons">
                    <div *ngIf="aloneProperty.value.toString() === '1'" class="{{getDataForProperty(aloneProperty)?.icon}} equip-prop-status-container"></div>
                    <div *ngIf="aloneProperty.value.toString() === '0'" class="{{getDataForProperty(aloneProperty)?.iconOff}} equip-prop-status-container"></div>
                  </div>
                  <div class="margin-top-5 ellipsis">{{aloneProperty.name}}</div>
                  <div class="margin-top-5">
                    <div *ngIf="aloneProperty.value.toString() === '1'">{{getDataForProperty(aloneProperty).nameForTrue | genComTranslate}}</div>
                    <div *ngIf="aloneProperty.value.toString() === '0'">{{getDataForProperty(aloneProperty).nameForFalse | genComTranslate}}</div>
                  </div>
              </div>
              <ng-template #noSingleProperty>
                <div [ngClass]="[deviceHasOnlyStatusProps(genEquip)? 'device-card': 'device-card-with-link']" class="width-full">
                  <div class="card-row-of-icons">
                    <div class="i-general-equipment"></div>
                  </div>
                  <div class="margin-top-5 ellipsis">{{genEquip.name}}</div>
                </div>
              </ng-template>
            </ng-template>
          </div>
           </div>
           <ng-container *ngIf="USE_DALI">
            <ng-container *ngFor="let daliScene of getDaliScenes(room?.id); let i = index"  >
            <div  [ngStyle]="setPosition(daliScene.position)">
              <div class="flex-center flex-row no-wrap">
                <div *ngIf="showPosition" class="flex-column">
                  <div *ngIf="showUpPositionArrow(daliScene.position)" class="i-arrow-up" (click)="moveDeviceUp(daliScene)"></div>
                <div *ngIf="showDownPositionArrow(daliScene.position)" class="i-arrow-up rotate180" (click)="moveDeviceDown(daliScene)"></div>
                </div>
             <div class="device-card-with-link width-full" (click)="onClickDaliScene(daliScene)">
              <div class="card-row-of-icons">
                      <div class="i-scenes-bl"></div>
                      <ng-container *ngIf="daliScene.isTwoState">
                        <div  *ngIf="!loadingElement(daliScene.id+'off')"
                        class="i-stop-bl power-btn-circle"
                        [ngClass]="{ 'background-orange': !daliScene.active }"
                        (click)="toggleDaliSceneOff(daliScene, $event)"></div>
                        <div *ngIf="loadingElement(daliScene.id+'off')" class="lds-spinner-small  margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </ng-container>
                <div  *ngIf="!loadingElement(daliScene.id+'on')"
                  class="i-play-no-border-bl power-btn-circle"
                  [ngClass]="{ 'background-orange': daliScene.active && daliScene.isTwoState}"
                  (click)="activateDaliScene(daliScene, $event)">
                </div>
                  <div *ngIf="loadingElement(daliScene.id+'on')" class="lds-spinner-small margin-left2 margin-right5 margin-top5"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
              <div class="margin-top-5 ellipsis-2-rows">{{daliScene.name}}</div>
             </div>
              </div>
            </div>
            </ng-container>
          </ng-container>
      </div>
  </div>
</div>
