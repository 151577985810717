import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DaliProject } from 'src/app/core/models/project/dali-project.model';
import { Blind } from 'src/app/core/models/project/devices/blind.model';
import { DaliGroup } from 'src/app/core/models/project/devices/daliGroup.model';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Light } from 'src/app/core/models/project/devices/light.model';
import { RgbLight } from 'src/app/core/models/project/devices/rgbLight.model';
import { LightsGroup } from 'src/app/core/models/project/groups/lights-group';
import { DaliScene } from 'src/app/core/models/scenes/dali-scene.model';

@Injectable({
  providedIn: 'root'
})
export class DaliProjectService {

  private daliProject: DaliProject;
  daliProjectChanged = new Subject<DaliProject>();

  constructor() { }

  setProject(daliProject: DaliProject) {
    this.daliProject = daliProject;
    this.daliProjectChanged.next(this.daliProject);
  }

  getProject() {
      return this.daliProject;
    }

  findDevicesInRoom(listOfDevices: any[], roomId: string) {
    const devicesInRoom = listOfDevices.filter(device => {
      return  (device.listOfLocationIds.some((locationId: string) => {
        return locationId.includes(roomId);
      }));
    });
    return devicesInRoom;
  }


  getDaliLightsForRoom(roomId: string): Light[] {
    if (!this.daliProject) {
      return [];
    }
    const lights: Light[] = this.findDevicesInRoom(this.daliProject.listOfLights, roomId);
    return lights;
  }

  getDaliBlindsForRoom(roomId: string): Blind[] {
    if (!this.daliProject) {
      return [];
    }
    const blinds: Blind[] = this.findDevicesInRoom(this.daliProject.listOfBlinds, roomId);
    return blinds;
  }

  getDaliRgbLightsForRoom(roomId: string): RgbLight[] {
    if (!this.daliProject) {
      return [];
    }
    const lights: RgbLight[] = this.findDevicesInRoom(this.daliProject.listOfRgbs, roomId);
    return lights;
  }

  getDaliLightsGroupForRoom(roomId: string): DaliGroup[] {
    if (!this.daliProject) {
      return [];
    }
    const lights: DaliGroup[] = this.findDevicesInRoom(this.daliProject.listOfGroups, roomId);
    return lights;
  }

  getDaliScenesForRoom(roomId: string): DaliScene[] {
    if (!this.daliProject) {
      return [];
    }
    const scenes: DaliScene[] = this.daliProject.listOfScenes.filter((sc: DaliScene)=> {
      return sc.locationIds?.includes(roomId);
    })
    return scenes;
  }

  getDaliLight(daliLightId: string): Light {
    return  this.daliProject?.listOfLights.find((dLight) => {
      return dLight.id === daliLightId;
    });
  }

  getDaliRgbLight(daliLightId: string): RgbLight {
    return  this.daliProject?.listOfRgbs.find((dLight) => {
      return dLight.id === daliLightId;
    });
  }

  getDaliLightsGroup(daliLightGroupId: string) {
    return  this.daliProject?.listOfGroups.find((group: DaliGroup) => {
      return group.id === daliLightGroupId;
    });
  }

  getDaliBlinds(daliBlindId: string) {
    return  this.daliProject?.listOfBlinds.find((blind) => {
      return blind.id === daliBlindId;
    });
  }

  getDaliBlindsBySceneId(daliBlindSceneId: string) {
    return  this.daliProject?.listOfBlinds.find((blind) => {
      return blind.equipmentProperties.some((prop) => {
        return prop.id === daliBlindSceneId;
      });
    });
  }

  getDaliScene(daliSceneId: string) {
    return  this.daliProject?.listOfScenes.find((daliScene) => {
      return daliScene.id === daliSceneId;
    });
  }

  findDaliDevice(id: string) {
    let device: any = this.getDaliLight(id);
    if (!device) {
      device = this.getDaliRgbLight(id);
    }
    if (!device) {
      device = this.getDaliLightsGroup(id);
    }
    if (!device) {
      device = this.getDaliBlinds(id);
    }
    if (!device) {
      device = this.getDaliLightsGroup(id);
    }

    return device;
  }

  updateDaliProjectByMqtt(daliType: string, daliId: string, payload: string) {
    if (daliType === 'light') {
      const daliLight = this.daliProject.listOfLights.find((l) => {
        return l.id === daliId;
      });
      if (daliLight) {
        const newValue = payload.split('|')[1];
        daliLight.equipmentProperties[0].value = newValue;
      }
    } else if (daliType === 'rgb') {
      const daliRgbLight =  this.daliProject.listOfRgbs.find((rgb) => {
        return rgb.id === daliId;
      });
      if (daliRgbLight) {
        let newValue: string;
        if (payload.split('|')[1]) {
          newValue = `${payload.split('|')[1]}|${payload.split('|')[2]}`;
        } else {
          newValue = `${payload.split('|')[1]}`;
        }
        daliRgbLight.value = newValue;
      }
    } else if (daliType === 'scene') {
      const daliScene =  this.daliProject.listOfScenes.find((sc) => {
        return sc.id === daliId;
      });
      const newValue = payload.split('|')[1];
      if (Number(newValue) === 0) {
        daliScene.active = false;
      } else {
        daliScene.active = true;
      }
    }
    else if (daliType === 'group') {
      const daliGroup = this.daliProject.listOfGroups.find( (group: LightsGroup) => {return group.id == daliId}) as LightsGroup
      const newValue = payload.split('|')[1];
      daliGroup.value = +newValue;
    }
    this.daliProjectChanged.next(this.daliProject);
  }

  getActiveDaliLightsForRoom(roomId: string): Device[] {
    if (this.daliProject) {
       const daliLightsInRoom: Device[] = this.getDaliLightsForRoom(roomId);
       return daliLightsInRoom?.filter((l) => {
         return Number(l.equipmentProperties[0].value > 0);
   });
    } else {
      return [];
    }
 }

 getActiveDaliRgbLightsForRoom(roomId: string): RgbLight[] {
   if (this.daliProject) {
      const daliRgbLightsInRoom: RgbLight[] = this.getDaliRgbLightsForRoom(roomId);
      return daliRgbLightsInRoom?.filter((rgb) => {
        return rgb.value !== '#000000|0';
  });
   } else {
     return [];
   }
}

isDaliLight(daliLight) {
  return (daliLight?.id?.split('-')[0] === 'DL');
 }

 daliLightIsDimmable(daliLight) {
  return Light.isDimmableDaliLight(daliLight);
}

daliLightIsRgb(daliLight) {
  return (daliLight?.id?.split('-')[0] === 'DRGB');
}

daliLightIsGroup(daliLight) {
  return (daliLight?.id?.split('-')[0] === 'DG');
}

changePropertyValueForDemo(deviceID: string, value: string) {
  const device = this.findDaliDevice(deviceID);
  if (this.isDaliLight(device) || this.daliLightIsDimmable(device)) {
    device.equipmentProperties[0].value = value;
  } else if (this.daliLightIsRgb(device) || this.daliLightIsGroup(device)) {
    device.value = value;
  }
}

}
