<nav class="flex-row header">
    <ion-buttons slot="start">
        <ion-menu-button color="light"></ion-menu-button>
      </ion-buttons>
    <div class="loading-spinner-container">
      <div *ngIf="loadingElements.length > 0" class="lds-spinner-small-wh position-absolute" ><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      <div *ngIf="loadingElements.length > 1" class="loading-spinner-inner" >{{loadingElements.length}}</div>
    </div>

    <div *ngIf="(allConfigsArray$ | async)?.length > 1 || User.isAdmin(user)" class="flex-row-center-vertical object-name-container">
    <div class="ellipsis-on-small-screen">{{(loadedConfig$| async)?.configuration?.OBJECT_NAME}}</div>
  </div>
    <app-statuses class="to-right margin-right10 align-self-center"></app-statuses>
    <div class="flex-row">
        <div *ngIf="User.isAdmin(user) || User.isOwner(user)"
          class="show-on-full-screen tooltip margin-top10">
            <i [ngClass]="[cardReaderConnected ? 'i-reader-sm' : 'i-reader-denied-sm']"></i>
        </div>
        <div *ngIf="unconfirmedAlerts > 0 && User.isAdmin(user)"
            routerLink="/alarms/unconfirmed" class="clickable margin-top15 outline-none tooltip  margin-right10">
            <i class="i-alert-sm"></i>
            <span *ngIf="unconfirmedAlerts > 0" class="alarm-count">{{ unconfirmedAlerts }}</span>
            <span *ngIf="unconfirmedAlerts > 0" class="bottom move-left25">{{ 'unconfirmed_alerts' | translate }}</span>
            <span *ngIf="unconfirmedAlerts === 0" class="bottom move-left25">{{ 'no_unconfirmed_alerts' | translate }}</span>
        </div>
        <div *ngIf="project?.listOfApartments.length > 1"
          class="header-dropdown"
         >
            <div *ngIf="project" class="text-tooltip">
                <div class="header-location flex-row"
                (click)="this.dropdownOpened = !this.dropdownOpened;">
                    <div class="dropdown-title-text ellipsis-on-small-screen">{{apartmantName | textSizeFilterPipe : 20}}</div>
                    <span class="bottom"
                    *ngIf="apartmantName?.length > 20">{{apartmantName}}</span>
                    <i class="header-arrow" [ngClass]="[dropdownOpened ? 'up' : 'down']"></i>
                </div>
            </div>
        </div>
        <div *ngIf="dropdownOpened" class="header-dropdown-content" [@enterAnimation]>
            <div *ngIf="buildingClicked === false">
                <a *ngFor="let building of project?.listOfBuildings" class="header-link-building" (click)="onBuildingSelected(building.id)">{{building.name}}</a>
            </div>
            <div *ngIf="buildingClicked === true">
                <a *ngFor="let apartment of apartments" class="header-link-building" (click)="onApartmentSelected(apartment.id)">{{apartment.name}}</a>
            </div>
        </div>
    </div>
</nav>
